import React from "react";
import {v4 as uuid} from 'uuid';
import { S3Image } from 'aws-amplify-react';
import defaultImage from "../../assets/img/logo-global.png";


class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null
    };
  }

  onChange = async (e) => {
    const fileName = uuid();
    this.setState({ 
      fullKey: fileName,
      file: e.target.files[0]
    })
    this.props.updatePhoto(fileName)
  }

  render() {
    return (
      <div className="picture-container">
        <div className="picture-logo">
          {this.props.imageKey || this.state.fullKey ? (
            <S3Image 
              level="public"
              body={this.state.file}
              imgKey={this.state.fullKey ? this.state.fullKey : this.props.imageKey} 
            />
          ) : (
            <img
              src={defaultImage}
              alt="Avatar"
            />
          )}
          <input type="file" accept='image/*' onChange={this.onChange} />
        </div>
      </div>
    );
  }
}

export default ImageUpload;
