import React from "react";
import { I18n } from 'aws-amplify';
import {dict} from "./variables/dictionary"
import App from "./App";

I18n.putVocabularies(dict);

if (!['zh', 'en'].includes(window.navigator.language)) {
  I18n.setLanguage('en')
}

class AppWithAuth extends React.Component {
  render() {
    return (
      <div>
        <App />
      </div>
    );
  }
}

export default AppWithAuth;