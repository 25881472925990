import React from "react"; 
import {Row, Col, Container} from "reactstrap";
import vivaLogo from "../../assets/img/Viva_Color.png";
import vivaLogo2 from "../../assets/img/Viva_Color_lema.png";
import loungeLogo from "../../assets/img/Logo The Lounge.png";
import partnershipLogo from "../../assets/img/Global Lounge Logo.png";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'; 
import {
    faBed,
        faCocktail, 
        faGlassWater, 
        faHorse, 
        faTable, 
        faTv, 
        faWifi} from '@fortawesome/free-solid-svg-icons';

const vivaDomain = "viva-cards.inflybo.com";

const VivaTitle = (props) => {
    if((props.currentDomain === vivaDomain)){
        return (
            <Container>
                <h3 style={{textAlign: "center", color:'#008EAA', fontWeight:"bold", margin: "0px"}}>Viva Air VIP Lounge Day Passes</h3>
                <p style={{textAlign: "center", color:'#008EAA', marginBotton:"10px"}}>Disfruta una experiencia llena de confort en las salas VIP</p>
            </Container>
        );
    } else {
        return null;
    }
}

const VivaCardTitle = (props) => {
    let title = "";
    if((props.cardTitle === "Viva VIP Lounge Day Pass I **Colombia**")) {
        title = "Colombia";
    } else if(props.cardTitle === "Viva VIP Lounge Day Pass I **Cancun**") {
        title = "Cancun";
    } else if(props.cardTitle === "Viva VIP Lounge Day Pass I **Mexico, D.F.**") {
        title = "Mexico, D.F.";
    }

    return <h2 style={{textAlign:"center", fontWeight:"bold", margin:'10px'}}>{title}</h2>;
}

const VivaAirDiscountInfo = (props) => {
      if((props.card.partnerDomain === vivaDomain)) {
      return (
        <>
          <h5 style={{textAlign: 'center', color: 'black', fontWeight: 'bolder', margin: '0px'}}>10% DE DESCUENTO</h5>
          <p style={{textAlign: 'center'}}>para el pase de 1 dia en las salas <b>VIP</b></p>
        </>
      );
    } else {
      return null;
    }
}

const VivaDiscountRequirements = (props) => {
    if((props.currentDomain === vivaDomain)) {
        return (
            <>
                <b><p>Para disfrutar de este servicio-debes ser el titular de la tarjeta. 
                    No es transferible. 
                Si deseas ingresar un invitado. deberas pagar el valor del día. 
                El pase es por 4 horas a partir del ingreso a la sala.</p></b>
            </>
        );
    } else {
        return null;
    }
}

const VivaFeatures = () => {
    return (
        <>
            <div style={{textAlign:'center', color:'black'}}>
                <p style={{margin:'0px'}}><b>Visitanos en:</b></p>
                <span style={{fontSize:'0.8em', fontStyle:'italic'}}>
                    Canc&uacute;n | M&eacute;xico D.F. | Medellin Internacional | Bucaramanga 
                    | C&uacute;cuta | Monteria | Santa Marta | Mendellin
                </span>

                <p style={{margin:'15px'}}><b>Incluye:</b></p>
                <Row style={{display:"flex", alignItems:"center", justifyContent:"center", flexWrap: "wrap"}}>
                    <Col style={{display:'inline-flex', verticalAlign:"center"}}>
                        <FontAwesomeIcon icon={faWifi} size="lg" color="#008EAA" /> 
                        <p style={{fontSize:'0.7em'}}> &nbsp;&nbsp;Wi-Fi</p>
                    </Col>
                    <Col style={{display:'inline-flex'}}>
                        <FontAwesomeIcon icon={faCocktail} size="lg" color="#008EAA"/> 
                        <p style={{fontSize:'0.7em'}}> Barra de Comidas</p>
                    </Col>
                    <Col style={{display:'inline-flex'}}>
                        <FontAwesomeIcon icon={faGlassWater} size="lg" color="#008EAA"/> 
                        <p style={{fontSize:'0.7em'}}> Bara de bebidas</p>
                    </Col>
                    <Col style={{display:'inline-flex'}}>
                        <FontAwesomeIcon icon={faBed} size="lg" color="#008EAA"/> 
                        <p style={{fontSize:'0.7em'}}> Sala de Descanso</p>
                    </Col>
                    <Col style={{display:'inline-flex'}}>
                        <FontAwesomeIcon icon={faTable} size="lg" color="#008EAA"/> 
                        <p style={{fontSize:'0.7em'}}> Sala de Reuniones</p>
                    </Col>
                    <Col style={{display:'inline-flex'}}>
                        <FontAwesomeIcon icon={faTv} size="lg" color="#008EAA"/> 
                        <p style={{fontSize:'0.7em'}}> Sal&oacute;n de TV</p>
                    </Col>
                    <Col style={{display:'inline-flex'}}>
                        <FontAwesomeIcon icon={faHorse} size="lg" color="#008EAA"/> 
                        <p style={{fontSize:'0.7em'}}> &nbsp;&nbsp;Sal&oacute;n de ni&#241;os</p>
                    </Col>
                </Row>
            </div>
        </>
    );
}

const VivaPartnershipLogo = () => {
    return (
        <div className="row">
            <div className="col-sm-6">
                <img src={loungeLogo} alt="lounge logo"></img>
            </div>
            <div className="col-sm-6" style={{borderLeft:'1px solid black', borderColor:'#008EAA'}}>
                <img src={partnershipLogo} alt="partnership logo"></img>
            </div>
        </div>
    );
}

// HEADER TITLE
class VivaLogo extends React.Component {
    constructor(props) {
        super(props); 
    }
    
    render() {
        if((this.props.card.partnerDomain === vivaDomain)) {
            // console.log(this.props.card.partnerDomain);
            const source = (this.props.card.name === "Viva VIP Lounge Day Pass I **Colombia**") ? vivaLogo2 : vivaLogo;
            const cls = (source === vivaLogo) ? "mb-5" : "";
            return (
                <div className="picture-container">
                    <div className="picture-logo">
                        <img
                            src={source}
                            alt="Viva Logo"
                            className={cls}
                        ></img>
                    </div>    
                </div>
            );
        } else {
            return null;
        }
    }
}

export default VivaLogo;
export {
    VivaAirDiscountInfo, 
    VivaDiscountRequirements, 
    VivaTitle, 
    VivaCardTitle, 
    VivaFeatures, 
    VivaPartnershipLogo
};
