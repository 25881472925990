/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPassengersCards = /* GraphQL */ `
  mutation CreatePassengersCards(
    $input: CreatePassengersCardsInput!
    $condition: ModelPassengersCardsConditionInput
  ) {
    createPassengersCards(input: $input, condition: $condition) {
      id
      email
      phone
      name
      status
      loungeCode
      loungeName
      membership
      price
      backgroundColor
      lettersColor
      cardNumber
      expirationDate
      startDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePassengersCards = /* GraphQL */ `
  mutation UpdatePassengersCards(
    $input: UpdatePassengersCardsInput!
    $condition: ModelPassengersCardsConditionInput
  ) {
    updatePassengersCards(input: $input, condition: $condition) {
      id
      email
      phone
      name
      status
      loungeCode
      loungeName
      membership
      price
      backgroundColor
      lettersColor
      cardNumber
      expirationDate
      startDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePassengersCards = /* GraphQL */ `
  mutation DeletePassengersCards(
    $input: DeletePassengersCardsInput!
    $condition: ModelPassengersCardsConditionInput
  ) {
    deletePassengersCards(input: $input, condition: $condition) {
      id
      email
      phone
      name
      status
      loungeCode
      loungeName
      membership
      price
      backgroundColor
      lettersColor
      cardNumber
      expirationDate
      startDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRooms = /* GraphQL */ `
  mutation CreateRooms(
    $input: CreateRoomsInput!
    $condition: ModelRoomsConditionInput
  ) {
    createRooms(input: $input, condition: $condition) {
      id
      name
      loungeCode
      description
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRooms = /* GraphQL */ `
  mutation UpdateRooms(
    $input: UpdateRoomsInput!
    $condition: ModelRoomsConditionInput
  ) {
    updateRooms(input: $input, condition: $condition) {
      id
      name
      loungeCode
      description
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRooms = /* GraphQL */ `
  mutation DeleteRooms(
    $input: DeleteRoomsInput!
    $condition: ModelRoomsConditionInput
  ) {
    deleteRooms(input: $input, condition: $condition) {
      id
      name
      loungeCode
      description
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCards = /* GraphQL */ `
  mutation CreateCards(
    $input: CreateCardsInput!
    $condition: ModelCardsConditionInput
  ) {
    createCards(input: $input, condition: $condition) {
      id
      name
      cardCode
      features
      partnerDomain
      brand
      imageKey
      backgroundColor
      lettersColor
      price
      stripeId
      type
      guestNumber
      order
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCards = /* GraphQL */ `
  mutation UpdateCards(
    $input: UpdateCardsInput!
    $condition: ModelCardsConditionInput
  ) {
    updateCards(input: $input, condition: $condition) {
      id
      name
      cardCode
      features
      partnerDomain
      brand
      imageKey
      backgroundColor
      lettersColor
      price
      stripeId
      type
      guestNumber
      order
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCards = /* GraphQL */ `
  mutation DeleteCards(
    $input: DeleteCardsInput!
    $condition: ModelCardsConditionInput
  ) {
    deleteCards(input: $input, condition: $condition) {
      id
      name
      cardCode
      features
      partnerDomain
      brand
      imageKey
      backgroundColor
      lettersColor
      price
      stripeId
      type
      guestNumber
      order
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCoupons = /* GraphQL */ `
  mutation CreateCoupons(
    $input: CreateCouponsInput!
    $condition: ModelCouponsConditionInput
  ) {
    createCoupons(input: $input, condition: $condition) {
      id
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCoupons = /* GraphQL */ `
  mutation UpdateCoupons(
    $input: UpdateCouponsInput!
    $condition: ModelCouponsConditionInput
  ) {
    updateCoupons(input: $input, condition: $condition) {
      id
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCoupons = /* GraphQL */ `
  mutation DeleteCoupons(
    $input: DeleteCouponsInput!
    $condition: ModelCouponsConditionInput
  ) {
    deleteCoupons(input: $input, condition: $condition) {
      id
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
