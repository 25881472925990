import React from "react";
import Iframe from 'react-iframe'

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle
} from "reactstrap";

class Paid extends React.Component {
  render() {
    return (
      <>
        <div className="content">
          <Card className="card-pricing">
            <CardHeader>
              <CardTitle tag="h5">Membership Card Paid</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="card-icon icon-success">
                <i className="nc-icon nc-check-2" />
              </div>
              <ul>
                <li>Now you can enter to any lounge.</li>
                <li>We will send you an Email and a SMS with all the details.</li>
              </ul>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

export default Paid;
