import React from "react";
import { I18n } from "aws-amplify"
import App from '../components/CheckoutForm';

import {
  Card,
  CardBody,
  Col,
  CardTitle,
  CardHeader,
} from "reactstrap";

class UserProfile extends React.Component {
  render() {
    return (
      <>
        <Col md="6">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">{I18n.get('buyCard')}</CardTitle>
            </CardHeader>
            <CardBody>
              <Col md="12">
                <App {...this.props}/>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default UserProfile;
