export const dict = {
    'en': {
        'warning': "Due to Covid-19 some lounges are still not open and some are operating at irregular schedules.  If you have any questions before you purchase the passes, please send an email to info@globalloungenetwork.com as we look forward to clarify any doubts and answer any questions you may have.  Please note at this time all purchases are final and until further notice the cards/passes/memberships are non-refundable.",
        'warningSafe': "Please stay safe and thank you for visiting our lounges, the safest place to be at the airport.",
        'intro': "Membership Cards with unlimited access to all the VIP Lounges of Global Lounge Network, are for passengers only. Offering the safety and comfort to enjoy all of the amenities of each lounge and the convenience to visit throughout the validity of the card. As a safety regulation, Membership Cards are not transferable and the name must match the passenger's boarding pass issued by holding a same-day ticket for airline travel. Whether you are purchasing the card for yourself or someone else, please put the information of the passenger that will be using the card, as the system will send the card to the passenger's mobile device via SMS (text message) to show at the lounge.",
        'seeCards': 'See all the cards',
        'buy': 'buy',
        'buyCard': 'Buy a Membership Card',
        'name': 'Name',
        'namePlaceholder': 'User card name',
        'email': 'Email',
        'emailPlaceholder': 'User email',
        'phone': 'Phone number',
        'lounge': 'Lounge',
        'selectLounge': 'Select your lounge',
        'startDate': 'Start Date',
        'discountCode': 'Discount Code',
        'incorrectCode': 'The code is incorrect!',
        'check': 'Check',
        'pay': 'Pay',
        'validEmail': 'Please enter a valid email.'
    },
    'zh': {
        'warning': "由于Covid-19，一些休息室仍未开放，而一些休息室的时间表则不规则。 如果在购买通行证之前有任何疑问，请发送电子邮件至 info@globalloungenetwork.com，我们期待着澄清任何疑问并回答您可能遇到的任何问题。 请注意，目前所有购买都是最终的，除非另行通知，否则卡/通行证/会员资格概不退还。",
        'warningSafe': "请保持安全，并感谢您访问我们的休息室，这是机场最安全的地方。",
        'intro': "会员卡，可无限制使用全球所有全球休息室。 提供灵活性和舒适性，以享受每个休息室的所有便利设施，并在整个卡有效期内都方便访问。",
        'seeCards': '查看所有卡片',
        'buy': '购买',
        'buyCard': '购买会员卡',
        'name': '名称',
        'namePlaceholder': '用户卡名称',
        'email': '电子邮件',
        'emailPlaceholder': '用户电子邮件',
        'phone': '电话号码',
        'lounge': '休息室',
        'selectLounge': '选择您的休息室',
        'startDate': '开始日期',
        'discountCode': '优惠码',
        'incorrectCode': '代码不正确',
        'check': '检查一下',
        'pay': '工资',
        'validEmail': '请输入有效电子邮件。'
    }
};