/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPassengersCards = /* GraphQL */ `
  query GetPassengersCards($id: ID!) {
    getPassengersCards(id: $id) {
      id
      email
      phone
      name
      status
      loungeCode
      loungeName
      membership
      price
      backgroundColor
      lettersColor
      cardNumber
      expirationDate
      startDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPassengersCardss = /* GraphQL */ `
  query ListPassengersCardss(
    $filter: ModelPassengersCardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPassengersCardss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        name
        status
        loungeCode
        loungeName
        membership
        price
        backgroundColor
        lettersColor
        cardNumber
        expirationDate
        startDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRooms = /* GraphQL */ `
  query GetRooms($id: ID!) {
    getRooms(id: $id) {
      id
      name
      loungeCode
      description
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRoomss = /* GraphQL */ `
  query ListRoomss(
    $filter: ModelRoomsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoomss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        loungeCode
        description
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCards = /* GraphQL */ `
  query GetCards($id: ID!) {
    getCards(id: $id) {
      id
      name
      cardCode
      features
      partnerDomain
      brand
      imageKey
      backgroundColor
      lettersColor
      price
      stripeId
      type
      guestNumber
      order
      duration
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCardss = /* GraphQL */ `
  query ListCardss(
    $filter: ModelCardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCardss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        cardCode
        features
        partnerDomain
        brand
        imageKey
        backgroundColor
        lettersColor
        price
        stripeId
        type
        guestNumber
        order
        duration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoupons = /* GraphQL */ `
  query GetCoupons($id: ID!) {
    getCoupons(id: $id) {
      id
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCouponss = /* GraphQL */ `
  query ListCouponss(
    $filter: ModelCouponsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCouponss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPassengerCardByEmail = /* GraphQL */ `
  query GetPassengerCardByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelPassengersCardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPassengerCardByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        name
        status
        loungeCode
        loungeName
        membership
        price
        backgroundColor
        lettersColor
        cardNumber
        expirationDate
        startDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
