import React from "react";
import { Route } from "react-router-dom";
import AdminNavbar from "../../components/Navbars/AdminNavbar.jsx";
import Paid from "../../views/pages/Paid.jsx";

class PaidLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "white",
      activeColor: "primary",
      sidebarMini: false,
      groupName: props.groupName,
      type: props.type,
      roomID: '',
      showManualEntry: false,
      showPassengers: false
    };
  }
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        console.log('admin')
        return (
          <Route
            path={prop.path}
            render={(props) => (
              < prop.component {...props} />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  handleActiveClick = color => {
    if(this._isMounted) this.setState({ activeColor: color })
  };

  handleBgClick = color => {
    if(this._isMounted) this.setState({ backgroundColor: color })
  };

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      if(this._isMounted) this.setState({ sidebarMini: false })
    } else {
      if(this._isMounted) this.setState({ sidebarMini: true })
    }
    document.body.classList.toggle("sidebar-mini");
  };

  render() {
    return (
      <div className="wrapper">
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
          <Paid  {...this.props}/>
        </div>
      </div>
    );
  }
}

export default PaidLayout;
