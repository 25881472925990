/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "ExtMembershipApi",
            "endpoint": "https://236nngr0u1.execute-api.us-east-1.amazonaws.com/live",
            "region": "us-east-1"
        },
        {
            "name": "StripeApi",
            "endpoint": "https://y9yvgfradc.execute-api.us-east-1.amazonaws.com/live",
            "region": "us-east-1"
        },
        {
            "name": "StripeWebHook",
            "endpoint": "https://f3ms655v14.execute-api.us-east-1.amazonaws.com/live",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://c3urzfpf7zawxha7egnpayzliq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-cvhze2tawjfcrlnjxenfqq2yle",
    "aws_cognito_identity_pool_id": "us-east-1:2cd0a5ab-cd68-4255-ab74-299a58db0112",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fVTTZywge",
    "aws_user_pools_web_client_id": "7ppp3cuen5d58vdohjn4hb1oah",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "inflybo-card866be88b1f5f4b61949144419a20f434101926-live",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
