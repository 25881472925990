import React from "react";
import classnames from "classnames";
import NotificationAlert from "react-notification-alert";
import { NavLink, Link } from "react-router-dom";
import globalLogo from "../../assets/img/logo-global.png";
import PropTypes from 'prop-types';

import {
  NavbarBrand,
  Navbar,
  Container,
  Collapse,
  Nav,
  NavItem,
} from "reactstrap";

class AdminNavbar extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      collapseOpen: false,
      color: "bg-white",
      timer: null
    };
  }

  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.showPassengers !== prevState.showPassengers || nextProps.showManualEntry !== prevState.showManualEntry){
      return { 
        showPassengers: nextProps.showPassengers,
        showManualEntry: nextProps.showManualEntry
      };
    }
    else return null;
  }

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
  
  render() {
    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        <Navbar
          className={classnames("navbar-absolute fixed-top", this.state.color)}
          expand="lg"
        >
        <Container style={{marginLeft: "0px"}}>
          <div className="navbar-wrapper">
            <NavbarBrand  onClick={e => e.preventDefault()}>
                <div className="logo-img">
                  <img style={{ height: '65px', paddingLeft:"0px", marginLeft: "0px" }} src={globalLogo} alt="react-logo" />
                </div>
            </NavbarBrand>            
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          {/* <Collapse
            isOpen={this.state.collapseOpen}
            className="justify-content-end"
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink to="/support" className="nav-link" style={{ textTransform: 'uppercase', color:"#1a8081"}} >
                  <i className="nc-icon nc-badge" />
                  Support
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse> */}
        </Container>
      </Navbar>
      </>
    );
  }
}

AdminNavbar.contextTypes ={
  router: PropTypes.object
};

export default AdminNavbar;
