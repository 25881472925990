import React from "react"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from "react-select";
import { SingleDatePicker } from 'react-dates';
import "react-dates/initialize";
import 'react-dates/lib/css/_datepicker.css';
import { API, graphqlOperation, Auth, I18n } from "aws-amplify";
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import {loadStripe} from '@stripe/stripe-js';

import {
  Col,
  Button,
  Input,
  Row,
  FormGroup,
  Label,
  Form
} from "reactstrap";
import { ConsoleLogger } from "@aws-amplify/core";

var _ = require('underscore');
var moment = require('moment')
const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const columns = ['id', 'email', 'phone', 'name', 'membership', 'expirationDate', 'startDate']

const env = process.env.NODE_ENV
let stripePromise;
// if(env === 'development'){
  if(env === 'live') {
  stripePromise = loadStripe('pk_test_51HyjanIixneKWvtE5fdpitkvSbv6Dyh6Pkv8bZ7e2yAkxCFQz4yX4dJJzp9Jg13J0SWrSUosnPsfIn9ZYsAzw7cI00oFl0t2JD');
} else {
  stripePromise = loadStripe('pk_live_51HyjanIixneKWvtES2mNwtL1PDspwHSN2ICzSHMRruDi7wN4TggmtHKJeE7jX6bLjQGtQIzU9SPKpL1kxbksNE7L00boUsPEQn');
}

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.handleDate = this.handleDate.bind(this)
    this.checkCode = this.checkCode.bind(this)
    this.state = {
      hasMoreCards: true,
      nextCardsToken: null,
      name: '',
      email: '',
      lounge: '',
      phone: '',
      coupon: '',
      guests: 0,
      wrongCode: true,
      startDate: moment(),
      rooms: [],
      price: props.card.price,
    }
    // console.log("CheckoutForm Constructor: props");
    // console.log(props);
  }

  componentDidMount() {
    this.getRooms()
  }

  handlePay = async (event) => {
    this.createPassengerCard()
    let apiName = 'StripeApi';
    let path = '/checkout-session';
    //const currentSession = await Auth.currentSession()
    let myInit = {
      body: {
        "card": this.props.card.name,
        "price": this.state.price,
        "email": this.state.email,
        "name": this.state.name,
      },
      headers: {
        'Content-Type': 'application/json',
      }
    }
    API.post(apiName, path, myInit).then(async res => {
    const stripe = await stripePromise;
    stripe.redirectToCheckout({
    sessionId: res.session.id
    }).then(function (result) {
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
    });
    }).catch(err => {
    console.log(err)
    })
  };

  async getRooms() {
    await API.graphql(graphqlOperation(queries.listRoomss)).then(response => {
      let rooms = response.data.listRoomss.items.map(room => {
        return {id: room.name, value: room.loungeCode, label: `${room.name} (${room.country})`, country: room.country}
      })
      if(this.props.card.type === 'Promotional') {
        rooms = _.filter(rooms, { id: 'YVR-SKYTEAM' })
      }

      if(this.props.card.name === 'Viva VIP Lounge Day Pass I **Colombia**') {
        rooms = _.filter(rooms, {country: 'Colombia'});
      }
      if(this.props.card.name === 'Viva VIP Lounge Day Pass I **Cancun**') {
        rooms = _.filter(rooms, (room)=>{
          if(room.country === 'Mexico' && (room.id === 'CUN-T2' 
            || room.id === 'CUN-T3' 
            || room.id === 'CUN-T4')) return true; 
        });
      }
      if(this.props.card.name === 'Viva VIP Lounge Day Pass I **Mexico, D.F.**') {
        rooms = _.filter(rooms, (room)=>{
          if(room.country === 'Mexico' && (room.id === 'MEX-AICMT2' 
            || room.id === 'HUX' 
            || room.id === 'PBC'
            || room.id === 'MEX-AICMT1')) return true; 
        });
      }
      this.setState({ rooms })
    }).catch(err => {
      console.log('error: ', err)
    })
  }

  async createPassengerCard() {
    const data = {}
    for (var i in this.state) {
      if ((this.state[i] !== '' || this.state[i].length !== 0) && columns.indexOf(i) !== -1) {
        data[i] = this.state[i]
      }
    }

    var oneYearFromNow = new Date(this.state.startDate);
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    data['membership'] = this.props.card.name
    data['price'] = this.state.price
    data['backgroundColor'] = this.props.card.backgroundColor
    data['lettersColor'] = this.props.card.lettersColor
    data['loungeCode'] = this.state.lounge.value
    data['loungeName'] = this.state.lounge.label
    data['expirationDate'] = moment(oneYearFromNow).format('YYYY-MM-DD')
    data['status'] = 'pending'
    data['startDate'] = moment(this.state.startDate).format('YYYY-MM-DD')
    data['cardNumber'] = this.generateNumber()

    await API.graphql(graphqlOperation(mutations.createPassengersCards,
      { input: data }
    )).then(response => {
      console.log("generated passenger card")
      console.log(response)
    }).catch(err => {
      console.log("failed to generate passenger card");
      console.log('error: ', err)
    })
  }

  generateNumber() {
    const number = Math.floor(100000 + Math.random() * 900000);
    const loungeCode = this.state.lounge.value
    const cardCode = this.props.card.cardCode
    const guestNumber = this.props.card.guestNumber
    const cardNumber = `${loungeCode}${cardCode}${number}${guestNumber}`
    this.setState({ cardNumber })
    return cardNumber
  }

  async checkCode() {
    await API.graphql(graphqlOperation(queries.getCoupons,
      { id: this.state.coupon }
    )).then(response => {
      if(response.data.getCoupons === null || response.data.getCoupons.status !== 'active') {
        this.setState({ wrongCode: true })  
      } else {
        this.setState({ wrongCode: false })
      }
    }).catch(err => {
      console.log('error: ', err)
      this.setState({ wrongCode: true })
    })
  }

  handleChange = (event) => {
    let change = {};
    if(event.target.name === "guests") {
      let guestCount = 0; 
      if(event.target.value && (event.target.value > 0)) {
        guestCount = parseInt(event.target.value); 
      }
      let entries = (guestCount + 1);
      let newPrice = entries * this.props.card.price;
      change['price'] = newPrice.toString();
      change['guests'] = guestCount; 
    } else {
      change[event.target.name] = event.target.value;
    }
    this.setState(change);
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.handlePay(this.props.card.stripeId)
  };

  handleDate(e) {
    this.setState({ startDate: e.target.value })
  }

  render() {
    // console.log("Checkoutform Render: state")
    // console.log(this.state)
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Col md="12">
            <Label style={{ color: '#00000' }}>{I18n.get('name')} (*)</Label>
            <Input
              defaultValue={this.state.name}
              onChange={this.handleChange}
              name="name"
              type="text"
              placeholder={I18n.get('namePlaceholder')}
              required />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col md="12">
            <Label>{I18n.get('email')} (*)</Label>
            <Input
              defaultValue={this.state.email}
              onChange={this.handleChange}
              name="email"
              type="text"
              placeholder={I18n.get('emailPlaceholder')}
              required />
            {!this.state.email.match(re) ? (
              <label style={{ 'color': '#ef8157' }}>
                {I18n.get('validEmail')}
              </label>
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup>
          <Col md="12">
            <Label>{I18n.get('phone')} (*)</Label>
            <PhoneInput
              country={'us'}
              inputStyle={{ paddingLeft: '45px', width: '100%' }}
              value={this.state.phone}
              onChange={phone => this.setState({ phone })}
            />
          </Col>
        </FormGroup>

        <br />
        <FormGroup>
          <Col md="12">
            <Row>
              <Col md="9">
                <Label>{I18n.get('lounge')} (*)</Label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="lounges"
                  defaultValue={this.state.lounge}
                  onChange={value => {
                    this.setState({ lounge: value })
                  }}
                  options={_.sortBy(this.state.rooms, 'label')}
                />
              </Col>
              <Col md="3">
                <Row>
                  <Col>
                    <Label>{I18n.get('startDate')} (*)</Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SingleDatePicker
                      date={this.state.startDate} // momentPropTypes.momentObj or null
                      numberOfMonths={1}
                      small
                      onDateChange={startDate => this.setState({ startDate })} // PropTypes.func.isRequired
                      focused={this.state.focused} // PropTypes.bool
                      onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                      id="your_unique_id" // PropTypes.string.isRequired,
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              {this.props.card.type === 'Promotional' ? (
                <>
                  <Col md="9">
                    <Label>{I18n.get('discountCode')} (*)</Label>
                    <Input
                      style={{ marginTop: '0px' }}
                      type="text"
                      name="coupon"
                      placeholder={I18n.get('discountCode')}
                      onChange={this.handleChange}
                      value={this.state.coupon}
                    />
                    {this.state.wrongCode ? (
                      <label style={{ 'color': '#ef8157' }}>{I18n.get('incorrectCode')}</label>
                    ) : null}
                    <Button
                      className="btn-round pull-left"
                      color="info"
                      onClick={this.checkCode}
                    >
                      {I18n.get('check')}
                    </Button>
                  </Col>
                  <Col md="3">
                    <Label>{I18n.get('Guests')} (*)</Label>
                    <Input
                      style={{ marginTop: '0px' }}
                      type="select"
                      name="guests"
                      onChange={this.handleChange}
                      value={this.state.guests}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </Input>
                  </Col>
                </>
              ) : null}
            </Row>
          </Col>
        </FormGroup>
        <Col>
          <Row>
            <Col md="6" style={{ marginTop: '20px'}}>
              <a href="/terms" target="_blank" style={{ cursor: "pointer", color: "#5e72e4" }}>Terms and conditions</a>
            </Col>
            <Col md="6">
              <Button
                className="btn-round pull-right"
                color="info"
                disabled={(this.props.card.type === 'Promotional' 
                  || this.state.name === '' 
                  || !this.state.email.match(re) 
                  || this.state.lounge === '' 
                  || this.state.phone === '' 
                  || this.state.startDate === ''
                ) && this.state.wrongCode}
              >
                {I18n.get('pay')} ${this.props.card.type !== 'Promotional' ? this.props.card.price : this.state.price}
              </Button>
              {this.state.error !== undefined ? (
                <label style={{ 'color': '#ef8157' }}>{this.state.error}</label>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Form>
    );
  }
}

export default Checkout;
